import { httpPost } from '@/api';
import { ErpTableOptions } from '@/types/type';
import { errorTip, showFiles } from '@/utils/publicMethods';
import { defineComponent, ref, toRaw } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const isBusinessRelated = [
    {
      label: '未设置业务收款',
      value: 0,
    },
    {
      label: '是',
      value: 1,
    },
    {
      label: '否',
      value: 2,
    },
  ];
  const statusIds: any = [];

  const columnList: ErpTableOptions[] = [
    {
      label: '流水号',
      prop: 'flowNo',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '交易时间',
      prop: 'transactionTime',
      minWidth: 160,
      type: 'time', // 筛选类型 time 日期
      filterProp: ['transactionTimeStartDate', 'transactionTimeEndDate'],
      value: [],
    },
    {
      label: '收款金额',
      prop: 'amount',
      propDesc: 'amountDesc',
      minWidth: 130,
      type: 'number',
      filterProp: ['amountMinVal', 'amountMaxVal'],
      value: [],
    },
    {
      label: '未关联收款金额',
      prop: 'writtenOffAmount',
      propDesc: 'writtenOffAmountDesc',
      minWidth: 170,
      type: 'number',
      filterProp: ['writtenOffAmountMinVal', 'writtenOffAmountMaxVal'],
      value: [],
    },
    {
      label: '摘要',
      prop: 'remark',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '付方名称',
      prop: 'payerName',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '付方账号',
      prop: 'payerAccountName',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '付方开户行名',
      prop: 'payerBankName',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '是否业务收款',
      prop: 'isBusiness',
      propDesc: 'isBusinessRelatedDesc ',
      minWidth: 160,
      type: 'signSelect',
      options: isBusinessRelated,
      value: '',
      component: defineComponent({
        template: `
        <el-radio-group v-model="row.isBusinessRelated" class="erp-radio" :disabled="!(hasPermission('erp:fmInfund:setInfundBusinessRelated')&&row.canEditBusinessRelated)"
        @change="changeBusinessRelated($event,row)">
        <el-radio :label="true">是</el-radio>
        <el-radio :label="false">否</el-radio>
      </el-radio-group>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const changeBusinessRelated = async (value, row: any) => {
            if (statusIds.includes(row.infundId)) {
              errorTip('点击间隔时间不能小于5秒');
              Instance.setupState.refreshTable(false);
            } else {
              statusIds.push(row.infundId);
              setTimeout(() => {
                statusIds.splice(statusIds.indexOf(row.infundId), 1);
              }, 5000);
              const res = await httpPost('/malicrm/fmInfund/setInfundBusinessRelated', {
                infundId: row.infundId,
                isBusinessRelated: value,
                version: row.version,
              });
              if (res.code === 200) {
                setTimeout(() => {
                  Instance.setupState.refreshTable(false);
                }, 100);
              } else {
                errorTip(res.message);
              }
            }
          };

          return { changeBusinessRelated };
        },
      }),
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '收款方名称',
      prop: 'principalFullName',
      minWidth: 180,
      type: 'input',
      value: '',
    },
    {
      label: '最近审核时间',
      minWidth: 160,
      prop: 'auditTime',
      type: 'time',
      value: [],
      filterProp: ['auditTimeStart', 'auditTimeEnd'],
    },
    {
      label: '收款备注',
      prop: 'memo',
      minWidth: 180,
      type: 'input',
      value: '',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 250,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain v-if="row.needAudit && hasPermission('erp:fmInfund:auditInfund')" @click='getAuditDetails(row)'>审核</el-button>
        <el-button type="primary" plain v-if="row.needPrint && hasPermission('erp:fmInfund:exportFmInfundList')" @click='toPrint(row)'>打印</el-button>
        <el-button v-if="row.memoUrl && hasPermission('erp:fminfund:downloadWatermark')" type="primary" plain @click='downLoadWaterBill(row)'>下载水单</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const getAuditDetails = (row) => {
            Instance.setupState.getAuditDetails(toRaw(row));
          };
          const toPrint = (row) => {
            Instance.setupState.toPrint(toRaw(row));
          };
          const downLoadWaterBill = (row) => {
            showFiles(row.memoName, row.memoUrl, false);
          };
          return { getAuditDetails, toPrint, downLoadWaterBill };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
