<template>
  <div class="print-center-dialog">
    <div class="print-wrap">
      <div ref="print">
        <table class="print-table table1">
          <tr>
            <td>审批编号</td>
            <td>{{ orderData.flowNo }}</td>
          </tr>
          <tr>
            <td>收款方名称</td>
            <td>{{ orderData.principalFullName }}</td>
          </tr>
          <tr>
            <td>收款总额</td>
            <td>{{ orderData.amountDesc }}</td>
          </tr>
          <tr>
            <td>收款时间</td>
            <td>{{ orderData.transactionTime }}</td>
          </tr>
          <tr>
            <td>付款方</td>
            <td>{{ orderData.payerName }}</td>
          </tr>
          <tr>
            <td>开户银行名称</td>
            <td>{{ orderData.payerBankName }}</td>
          </tr>
          <tr>
            <td>银行账号</td>
            <td>{{ orderData.payerAccountName }}</td>
          </tr>
        </table>
        <table class="print-table order" v-if="orderData.confirmList.length > 0">
          <tr>
            <th width="132">销售合同号</th>
            <th width="86">收款类型</th>
            <th width="120">核销金额</th>
            <th>客户</th>
          </tr>
          <tr v-for="(item, index) in orderData.confirmList" :key="index">
            <td>{{ item.businessContractNo }}</td>
            <td>{{ item.infundTypeDesc }}</td>
            <td>{{ item.actualAmountDesc }}</td>
            <td>{{ item.customerName }}</td>
          </tr>
          <tr v-if="orderData.confirmList.length > 1">
            <td>合计</td>
            <td></td>
            <td>{{ getTotal }}</td>
            <td></td>
          </tr>
        </table>
        <div class="print-time">
          打印时间：<b>{{ printTime }}</b>
          <span
            >打印人：<b>{{ orderData.printUser }}</b></span
          >
        </div>
      </div>
    </div>
    <div class="print-bottom">
      <span class="erp-default__btn" @click="back">关闭</span>
      <span class="erp-primary__btn" @click="doPrint">打印</span>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import Print from '@/utils/print/index';
export default {
  props: ['infundId'],
  data() {
    return {
      orderData: {
        flowNo: null,
        amountDesc: null,
        transactionTime: null,
        payerName: null,
        payerBankName: null,
        payerBankNo: null,
        principalFullName: null,
        total: 0,
        printUser: null,
        confirmList: [],
      },
      printTime: null,
    };
  },
  created() {
    this.updatePrintTime();
    this.initData(this.infundId);
  },
  computed: {
    getTotal() {
      let sumprice = this.orderData.confirmList.reduce(function (total, currentValue) {
        return Number(total) + Number(currentValue.actualAmount);
      }, 0);
      if (Number(sumprice)) {
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        sumprice = String(sumprice.toFixed(2)).replace(reg, '$1,');
      } else {
        sumprice = 0;
      }
      return sumprice;
    },
  },
  methods: {
    doPrint() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.updatePrintTime();
      setTimeout(function () {
        Print(that.$refs.print);
      }, 100);
    },
    initData(id) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.ajax({
        type: 'GET',
        url: `/malicrm/fmInfund/printFmInfundById/${id}`,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            that.orderData = Object.assign({}, that.orderData, res.result);
            that.updatePrintTime();
          } else {
            that.errorTip(res.message);
          }
        },
      });
    },
    back() {
      this.$emit('cancel', false);
    },
    updatePrintTime() {
      this.printTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
<style lang="scss">
.print-center-dialog {
  .print-bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 7px;
    line-height: 30px;
    border-top: 1px solid rgba(220, 223, 230, 1);
    padding-bottom: 8px;
    span {
      padding: 0 25px;
      border: 1px solid rgba(220, 223, 230, 1);
      background-color: #fff;
      vertical-align: middle;
      font-size: 14px;
      color: #303133;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      border-radius: 2px;
    }
    .erp-default__btn {
      background: #fff;
      cursor: pointer;
      border: 1px solid rgba(220, 223, 230, 1);
      color: #303133;
    }
    .erp-primary__btn {
      background: #2878ff;
      color: #fff;
      border-color: #2878ff;
      margin-left: 8px;
      margin-right: 5px;
    }
  }
}
.print-wrap {
  width: 844px;
  height: 100%;
  margin: 30px auto;
  margin-top: 18px;
  border: 1px dashed #e5e9f2;
  padding: 40px;
  min-height: 595px;
}
.print-table {
  border: 1px solid rgba(137, 137, 137, 1);
  width: 100%;
  border-collapse: collapse;
  line-height: 30px;
  font-size: 14px;
}
.print-table tr td,
.print-table tr th {
  border: 1px solid rgba(137, 137, 137, 1);
  color: #303133;
}
.print-time {
  text-align: right;
  margin-top: 9px;
  color: #606266;
}
.print-time > span {
  margin-left: 20px;
  display: inline-block;
}
.print-time b {
  color: #303133;
  font-weight: 400;
}
.print-table.order {
  margin-top: 10px;
}
.print-table.table1 {
  td {
    text-indent: 15px;
  }
}
.print-table.order {
  td {
    text-align: center;
    line-height: 1.2;
    padding: 0 15px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 8px;
  }
}
</style>
