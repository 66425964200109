
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import PrintPage from './print.vue';
import { erpConfirm, errorMessage, exportFile, loadPage } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import router from '@/router';
export default defineComponent({
  name: 'collection',
  components: { ErpList, PrintPage },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const formType = ref(1);
    const data = reactive({
      rowData: null,
    });
    // 这里是个典型的传递函数处理自定义实现
    const customFn = (filterParams) => {
      type Params = {
        isBusinessRelated: boolean | null;
        isBusinessRelatedNull: number | null;
      };
      let params: Params = {
        isBusinessRelated: null,
        isBusinessRelatedNull: null,
      };
      if (filterParams && filterParams.isBusiness !== null) {
        if (filterParams.isBusiness === 0) {
          params.isBusinessRelated = null;
          params.isBusinessRelatedNull = 1;
        }
        if (filterParams.isBusiness === 1) {
          params.isBusinessRelated = true;
          params.isBusinessRelatedNull = 0;
        }
        if (filterParams.isBusiness === 2) {
          params.isBusinessRelated = false;
          params.isBusinessRelatedNull = 0;
        }
        return params;
      } else {
        return {};
      }
    };
    // 打印
    const toPrint = (row) => {
      formType.value = 5;
      data.rowData = row;
      showVisible();
    };
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 审核
    const getAuditDetails = (row) => {
      loadPage('collectionAuditDetail', {
        id: row.infundId,
        version: row.version,
        path: 'collection',
      });
    };

    // 路由跳转
    const auditList = () => {
      router.push('/erp/financial/collectionAuditList');
    };

    // 导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/fmInfund/exportFmInfundList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    // 查看详情
    const rowClick = (row, column) => {
      const { label } = column;
      if (label !== '操作' && label !== '是否业务收款' && row.isBusinessRelated === true) {
        loadPage('collectionDetail', {
          id: row.infundId,
          version: row.version,
        });
      }
    };

    return {
      customFn,
      formType,
      toPrint,
      auditList,
      ...toRefs(data),
      getAuditDetails,
      exportFileFn,
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
    };
  },
});
