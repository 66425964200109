import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_PrintPage = _resolveComponent("PrintPage")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/fmInfund/queryFmInfundListWithPage",
      rowId: "infundId",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "collection",
      onRowClick: _ctx.rowClick,
      queryFn: _ctx.customFn
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:fmInfund:exportFmInfundList'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "erp-default__btn ml-8",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportFileFn && _ctx.exportFileFn(...args)))
            }, "导出"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:fmInfund:queryFmInfundAuditListWithPage'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "erp-default__btn ml-8",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.auditList && _ctx.auditList(...args)))
            }, "审核记录"))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick", "queryFn"]),
    (_ctx.visible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: _ctx.visible,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
          "custom-class": "print-dialog",
          title: "收款审批",
          width: "904px"
        }, {
          default: _withCtx(() => [
            (_ctx.formType == 5)
              ? (_openBlock(), _createBlock(_component_PrintPage, {
                  key: 0,
                  infundId: _ctx.rowData.infundId,
                  onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeVisible()))
                }, null, 8, ["infundId"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}